import './Detector.css';

import React from 'react';

import BannerImage from '../BannerImage';
import UploadFile from '../Dialogs/UploadFile';
import GetResults from '../GetResults';
import Instructions from '../Instructions';

class Detector extends React.Component {
  constructor() {
    super();
    this.state = {
      requests: [],
    };
    this.uploadRequested = this.uploadRequested.bind(this);
    this.fileUploaded = this.fileUploaded.bind(this);
    this.clearRequest = this.clearRequest.bind(this);
  }

  uploadRequested(requestId, fileName) {
    const req = {
      requestId,
      fileName,
      uploaded: false,
    };
    this.setState((prevState) => ({
      requests: [req, ...prevState.requests],
    }));
  }

  fileUploaded(requestId) {
    this.setState((prevState) => ({
      requests: prevState.requests.map((e) => (
        e.requestId === requestId ? ({ ...e, uploaded: true }) : e)),
    }));
  }

  clearRequest(requestId) {
    const {
      requests,
    } = this.state;
    const newList = requests.filter((i) => i.requestId !== requestId);
    this.setState({
      requests: newList,
    });
  }

  render() {
    const {
      requests,
    } = this.state;
    return (
      <div id="Detector">
        <div id="DetectorContainer">
          <BannerImage />
          <div id="DetectorContent">
            <UploadFile
              setFileState={this.setFileState}
              fileUploaded={this.fileUploaded}
              uploadRequested={this.uploadRequested}
            />
            {requests && requests.map((r) => (
              <GetResults
                key={r.requestId}
                requestId={r.requestId}
                fileName={r.fileName}
                uploaded={r.uploaded}
                clearRequest={this.clearRequest}
              />
            ))}
            <Instructions />
          </div>
        </div>
      </div>
    );
  }
}

export default Detector;
