import './Header.css';

import React from 'react';
import PropTypes from 'prop-types';

import HeaderNavFull from '../Menus/HeaderNavFull';
import HeaderNavDropdown from '../Menus/HeaderNavDropdown';

import DropdownOpened from '../Buttons/DropdownOpened';
import DropdownClosed from '../Buttons/DropdownClosed';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpened: false,
    };
    this.openDropdown = this.openDropdown.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
  }

  openDropdown() {
    this.setState({
      dropdownOpened: true,
    });
  }

  closeDropdown() {
    this.setState({
      dropdownOpened: false,
    });
  }

  render() {
    const {
      history,
    } = this.props;
    const { dropdownOpened } = this.state;
    return (
      <div id="Header">
        <div id="HeaderContainer">
          <div id="DropdownButton">
            {dropdownOpened
              ? (
                <DropdownOpened closeDropdown={this.closeDropdown} />
              )
              : (
                <DropdownClosed openDropdown={this.openDropdown} />
              )}
          </div>
          <div id="HeaderBrand">
            <a className="brand-nav" href="https://watchguard.com/">
              <img className="brand-logo" src="/images/watchguard-logo.svg" alt="home" />
            </a>
          </div>
          <HeaderNavFull />
          {dropdownOpened
            ? (
              <HeaderNavDropdown
                history={history}
                handleClose={this.closeDropdown}
              />
            )
            : ''}
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Header;
