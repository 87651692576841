import './GetResults.css';

import * as React from 'react';
import PropTypes from 'prop-types';
// import axios from 'axios';
import axios from '../../utils/axios';
import config from '../../utils/config.json';

function CheckResults(requestId) {
  return new Promise((resolve, reject) => axios.get(`${config.apiUrl}/get-results/${requestId}`)
    .then((res) => {
      if (res.status === 200) {
        resolve(res.data.results);
        return;
      }
      reject(new Error('Error checking file status'));
    })
    .catch(() => reject(new Error('Error checking file status'))));
}

class GetResults extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPending: true,
      serial: null,
      detected: false,
      errorMessage: null,
      retries: 0,
    };

    this.checkForResults = this.checkForResults.bind(this);
    this.stopChecking = this.stopChecking.bind(this);
    this.closeContainer = this.closeContainer.bind(this);
    this.interval = null;
  }

  componentDidMount() {
    this.checkForResults();
  }

  checkForResults() {
    const {
      requestId,
    } = this.props;
    const {
      retries,
    } = this.state;

    if (retries > 10) {
      this.setState({
        isPending: false,
        errorMessage: 'Error: Timeout. The request timed out. Please try again in a few minutes.',
      });
    } else {
      CheckResults(requestId)
        .then((res) => {
          if (res.status === 'error') {
            this.stopChecking();
            let errorMessage = 'Error checking file';
            if (res.message === 'missing_file') {
              errorMessage = 'Error: Upload Failed. The file upload timed out. Please try again.';
            }
            if (res.message === 'invalid_file') {
              errorMessage = 'Error: Invalid File. Please make sure you are uploading a support file.';
            }
            if (res.message === 'processing_error') {
              errorMessage = 'Error: Processing Error. Unexpected error while processing the file. Please contact WatchGuard support.';
            }
            if (res.message === 'results_retrieved') {
              errorMessage = 'Error: Results Already Retrieved. Results are deleted after first retrieval for privacy protections.';
            }
            this.setState({
              isPending: false,
              errorMessage,
            });
          } else if (res.status === 'complete') {
            this.stopChecking();
            this.setState({
              detected: res.detected,
              serial: res.serial,
              isPending: false,
            });
          } else {
            this.setState({
              retries: retries + 1,
            });
            this.interval = setTimeout(() => {
              this.checkForResults();
            }, 1000 * 5 * (retries + 1));
          }
        })
        .catch(() => {
          this.stopChecking();
          this.setState({
            isPending: false,
            errorMessage: 'Error: Unexpected Error. There was an unexpected error. Please try again in a few minutes.',
          });
        });
    }
  }

  stopChecking() {
    if (this.interval) {
      clearTimeout(this.interval);
    }
  }

  closeContainer(e) {
    const {
      requestId,
      clearRequest,
    } = this.props;
    e.preventDefault();
    this.stopChecking();
    clearRequest(requestId);
  }

  render() {
    const {
      errorMessage,
      isPending,
      serial,
      detected,
    } = this.state;
    const {
      fileName,
      uploaded,
    } = this.props;

    function getClassName() {
      if (isPending || errorMessage) {
        return 'result-pending';
      }
      if (detected) {
        return 'result-detected';
      }
      return 'result-clean';
    }

    return (
      <div id="GetResults">
        <div id="GetResultsContainer" className={getClassName()}>
          <div className="container-controls">
            <button
              className="close-container-button"
              tabIndex="0"
              type="button"
              onClick={this.closeContainer}
              onKeyPress={this.closeContainer}
            >
              Clear
            </button>
          </div>
          <div className="request-info">
            <div className="request-info-item">
              <b>Filename: </b>
              {fileName}
            </div>
          </div>
          <div className="request-info">
            <div className="request-info-item">
              <b>Serial: </b>
              {serial}
            </div>
          </div>
          {isPending && !uploaded
            ? (
              <div className="loading-spinner">
                <span><i className="fas fa-spinner fa-spin" /></span>
                <span className="loading-message">Uploading File</span>
              </div>
            )
            : ''}
          {isPending && uploaded
            ? (
              <div className="loading-spinner">
                <span><i className="fas fa-spinner fa-spin" /></span>
                <span className="loading-message">Processing File</span>
              </div>
            )
            : ''}
          {!isPending && !errorMessage
            ? (
              <div className="results">
                <div className="detection-result">
                  <b>Result: </b>
                  {detected ? 'Indicators Found' : 'No Indicators Found'}
                </div>
                <div className="results-instructions">
                  <b>Next Steps:</b>
                  { ' ' }
                  Follow the steps in the
                  { ' ' }
                  <i>{detected ? 'Remediate' : 'Prevent'}</i>
                  { ' ' }
                  section of
                  { ' ' }
                  <a
                    href="https://techsearch.watchguard.com/KB?type=Article&SFDCID=kA16S000000SNyiSAG&lang=en_US"
                    target="_blank"
                    rel="noreferrer"
                  >
                    this article
                  </a>
                  .
                </div>
              </div>
            )
            : ''}
          <div className={errorMessage ? 'upload-file-error' : 'upload-file-error hidden'}>
            {errorMessage}
          </div>
        </div>
      </div>
    );
  }
}

GetResults.propTypes = {
  requestId: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  uploaded: PropTypes.bool.isRequired,
  clearRequest: PropTypes.func.isRequired,
};

export default GetResults;
