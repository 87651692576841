import './Resources.css';

import React from 'react';

import BannerImage from '../BannerImage';

function Resources() {
  return (
    <div id="Resources">
      <div id="ResourcesContainer">
        <BannerImage />
        <div id="ResourcesContent">
          <h1>FAQs and Resources</h1>
          <div className="text-container">
            <p>
              WatchGuard has been working proactively with government authorities, and leading
              forensic experts, including Mandiant, the FBI, CISA, DOJ, and UK NCSC, to
              investigate and respond to the botnet. The investigations into Cyclops Blink are
              still ongoing; however, WatchGuard is committed to keeping our clients informed
              and empowered to eliminate this sophisticated threat. As such, WatchGuard will
              be maintaining a FAQ that will contain all information related to the Cyclops
              Blink botnet and how to prevent and remediate the threat.
            </p>
            <p>
              <a
                href="https://techsearch.watchguard.com/KB?type=Article&SFDCID=kA16S000000SOCGSA4&lang=en_US"
                target="blank"
                rel="noreferrer"
              >
                Visit the WatchGuard Cyclops Blink Client FAQ now.
              </a>
            </p>
            <p>
              Additional resources are available here:
            </p>
            <ul>
              <a
                href="http://detection.watchguard.com/#plan"
              >
                WatchGuard&apos;s 4-Step Cyclops Blink Diagnosis and Remediation Plan
              </a>
            </ul>
            <ul>
              <a
                href="https://techsearch.watchguard.com/KB?type=Article&SFDCID=kA16S000000SNyiSAG&lang=en_US"
                target="blank"
                rel="noreferrer"
              >
                Detailed Instructions for Enacting the 4-Step Cyclops Blink Diagnosis and
                Remediation Plan
              </a>
            </ul>
            <ul>
              <a
                href="https://techsearch.watchguard.com/KB?type=Article&SFDCID=kA16S000000SOCGSA4&lang=en_US"
                target="blank"
                rel="noreferrer"
              >
                Cyclops Blink Frequently Asked Questions (FAQ)
              </a>
            </ul>
            <ul>
              <a
                href="https://www.ncsc.gov.uk/news/joint-advisory-shows-new-sandworm-malware-cyclops-blink-replaces-vpnfilter"
                target="blank"
                rel="noreferrer"
              >
                Joint Government Advisory Issued by the FBI, CISA, NSA, and the UK NCSC
              </a>
            </ul>
            <ul>
              <a
                href="https://www.ncsc.gov.uk/news/joint-advisory-shows-new-sandworm-malware-cyclops-blink-replaces-vpnfilter"
                target="blank"
                rel="noreferrer"
              >
                Security Best Practices Provided By FBI, CISA, NSA, and UK NCSC
              </a>
              { ' ' }
              (page 9)
            </ul>
            <p>
              As always,
              { ' ' }
              <a
                href="https://www.watchguard.com/wgrd-support/contact-support"
                target="blank"
                rel="noreferrer"
              >
                WatchGuard Support
              </a>
              { ' ' }
              is available 24/7 to support customers and partners as they implement these fixes.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resources;
