import './Footer.css';

import React from 'react';
import { Row, Col } from 'react-bootstrap';

function Footer() {
  return (
    <div id="Footer">
      <div id="FooterContainer">
        <Row>
          <Col sm={12} md={{ span: 5, offset: 1 }} className="footer-copyright">
            <div className="content">
              <p>Copyright ©2022 WatchGuard Technologies, Inc. All Rights Reserved.</p>
            </div>
          </Col>
          <Col sm={12} md={5} className="footer-nav">
            <div className="content">
              <ul className="footer-nav-list">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="/Terms"
                  >
                    Terms of Use
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://watchguard.com/wgrd-about/privacy-policy"
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Footer;
