import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/js/all';

import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './js/components/App';
import history from './history';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter history={history}>
      <Routes>
        <Route path="/*" element={<App history={history} />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();
