import './DropdownOpened.css';

import React from 'react';
import PropTypes from 'prop-types';

function DropdownOpened(props) {
  const { closeDropdown } = props;
  return (
    <div
      id="DropdownOpened"
      tabIndex="0"
      role="button"
      onClick={closeDropdown}
      onKeyPress={closeDropdown}
    >
      <i className="fas fa-times dropdown-icon" />
    </div>
  );
}

DropdownOpened.propTypes = {
  closeDropdown: PropTypes.func.isRequired,
};

export default DropdownOpened;
