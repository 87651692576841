import './HeaderNavDropdown.css';

import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class HeaderNavDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    const { handleClose } = this.props;
    if (this.wrapperRef
        && !this.wrapperRef.current.contains(event.target)
        && event.target.tagName !== 'path'
        && event.target.id !== 'BurgerMenuIcon'
        && event.target.id !== 'HeaderNavDropdown') {
      handleClose();
    }
  }

  render() {
    const {
      handleClose,
    } = this.props;
    return (
      <Container id="HeaderNavDropdown" ref={this.wrapperRef}>
        <Row className="menu-item">
          <Col xs={12} className="dropdown-menu-item">
            <Link to="/" onClick={handleClose}>
              <div className="menu-item-label">Overview</div>
            </Link>
          </Col>
        </Row>
        <Row className="menu-item">
          <Col xs={12} className="dropdown-menu-item">
            <Link to="/Detector" onClick={handleClose}>
              <div className="menu-item-label">Detector</div>
            </Link>
          </Col>
        </Row>
        <Row className="menu-item">
          <Col xs={12} className="dropdown-menu-item">
            <Link to="/Resources" onClick={handleClose}>
              <div className="menu-item-label">FAQ &amp; Resources</div>
            </Link>
          </Col>
        </Row>

      </Container>
    );
  }
}

HeaderNavDropdown.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default HeaderNavDropdown;
