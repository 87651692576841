import './Instructions.css';

import React from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';

function Instructions() {
  return (
    <div id="Instructions">
      <div id="InstructionsContainer">
        <Row>
          <Col xs={12} id="InstructionsTitle">
            Instructions
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="instructions-section">
            <ol>
              <li>
                Follow the steps in the links below to retrieve a diagnostic log file
                (support.tgz) from your Firebox
                <br />
                <i className="subtext">
                  You do not have to enable diagnostic logging on the Firebox. You can
                  upload any support.tgz file, including a FireCluster support.tgz, to this tool.
                </i>
                <ul>
                  <li>
                    <a href="https://www.watchguard.com/help/docs/help-center/en-US/Content/en-US/Fireware/fsm/downoad_support_file.html">
                      Download the Diagnostic Log File in Firebox System Manager
                    </a>
                  </li>
                  <li>
                    <a href="https://www.watchguard.com/help/docs/help-center/en-US/Content/en-US/Fireware/system_status/support_diagnostics-file_web.html">
                      Download the Diagnostic Log File in Fireware Web UI
                    </a>
                  </li>
                  <li>
                    <a href="http://www.watchguard.com/help/docs/help-center/en-US/Content/en-US/WG-Cloud/Devices/managed/fireware_webui_diagnostics_file.html">
                      Download the Diagnostic Log File for a Cloud-Managed Firebox
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                Click
                {' '}
                <b>Select Diagnostic Log File</b>
                {' '}
                above to upload the file.
              </li>
              <li>
                Click
                {' '}
                <b>Submit</b>
                .
              </li>
            </ol>
            <p>
              This web application analyzes the file and tells you if it finds indicators that the
              Firebox is compromised.
            </p>
            <ul>
              <li>
                If you see the message:
                {' '}
                <b className="fail">Indicators Found</b>
                , follow the steps in
                the
                {' '}
                <i>Remediate</i>
                {' '}
                section of
                {' '}
                <a
                  href="https://techsearch.watchguard.com/KB?type=Article&SFDCID=kA16S000000SNyiSAG&lang=en_US#Remediate"
                  target="_blank"
                  rel="noreferrer"
                >
                  this article
                </a>
                .
              </li>
              <li>
                If you see the message:
                {' '}
                <b className="success">No Indicators Found</b>
                , follow the steps in the
                <i> Prevent</i>
                {' '}
                section of
                {' '}
                <a
                  href="https://techsearch.watchguard.com/KB?type=Article&SFDCID=kA16S000000SNyiSAG&lang=en_US#Prevent"
                  target="_blank"
                  rel="noreferrer"
                >
                  this article
                </a>
                .
              </li>
            </ul>
            <p>
              For information about how data is handled by the Cyclops Blink Web Detector and
              the other detection tools that WatchGuard provides, see
              { ' ' }
              <a
                href="https://techsearch.watchguard.com/KB?type=Article&SFDCID=kA16S000000SOBISA4&lang=en_US"
                target="_blank"
                rel="noreferrer"
              >
                this article.
              </a>
            </p>
          </Col>
        </Row>

      </div>
    </div>
  );
}

export default Instructions;
