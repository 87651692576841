import './Home.css';

import React from 'react';

import BannerImage from '../BannerImage';

function Home() {
  return (
    <div id="Home">
      <div id="HomeContainer">
        <BannerImage />
        <div id="HomeContent">
          <div className="text-container">
            <h3>What Happened</h3>
            <p>
              Working closely with the FBI, CISA, DOJ and UK NCSC,
              WatchGuard investigated and developed a remediation for Cyclops Blink, a
              sophisticated state-sponsored botnet that may have affected a limited number
              (estimated at ~1%) of
              WatchGuard firewall appliances. WatchGuard has concluded, based on our own
              investigation, an investigation conducted jointly with Mandiant, and
              information provided by the FBI, that there is no evidence of data exfiltration
              from WatchGuard or its customers, and firewall appliances are not at risk if they
              were never configured to allow unrestricted management access from the
              Internet. Below we provide an overview of what happened and steps
              { ' ' }
              <u>we recommend you take now</u>
              { ' ' }
              to eliminate the threat posed by malicious activity from the botnet.
            </p>
            <h3>What You Should Do</h3>
            <p>
              In response to this sophisticated, state-sponsored attack, WatchGuard has developed
              and released a set of simple and easy-to-implement Cyclops Blink detection tools,
              as well as a 4-Step Cyclops Blink Diagnosis and Remediation Plan to help customers
              diagnose, remediate if necessary, and prevent future infection. WatchGuard, supported
              by the FBI, CISA, NSA and UK NCSC, strongly recommends that all WatchGuard customers
              promptly take the actions outlined in the 4-Step Cyclops Blink Diagnosis and
              Remediation Plan to eliminate the threat posed by malicious activity from the botnet.
            </p>
          </div>
          <div className="text-container">
            <h2 id="plan">Cyclops Blink 4-Step Diagnosis and Remediation Plan</h2>
            <h5>STEP 1: Diagnose</h5>
            <p>
              WatchGuard has released a set of Cyclops Blink detection tools. Each tool provides
              immediate and accurate diagnosis and lets you know if your firewall appliance is
              infected. Use the information in this table to help you decide which tool is best
              for you:
            </p>
            <table className="tool-compatibility-table">
              <thead>
                <tr>
                  <th>
                    { '  '}
                  </th>
                  <th>
                    Cyclops Blink Web Detector
                  </th>
                  <th>
                    WatchGuard System Manager Cyclops Blink Detector
                  </th>
                  <th>
                    WatchGuard Cloud Cyclops Blink Detector
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    Appliance compatibility
                  </th>
                  <td>
                    All
                  </td>
                  <td>
                    All
                  </td>
                  <td>
                    Only for appliances that have been added to WatchGuard Cloud for logging,
                    reporting, or management.
                  </td>
                </tr>
                <tr>
                  <th>
                    Access
                  </th>
                  <td>
                    Public website with no access restrictions.
                  </td>
                  <td>
                    Must download and install WSM 12.7.2 Update 2 or higher.
                  </td>
                  <td>
                    Must have a WatchGuard Cloud account with firewall appliances added for
                    logging, reporting, or management.
                  </td>
                </tr>
                <tr>
                  <th>
                    Process
                  </th>
                  <td>
                    Download diagnostic log file (support.tgz) file from firewall appliance;
                    visit secure Cyclops Blink Web Detector (
                    <a href="https://detection.watchguard.com/Detector">
                      detection.watchguard.com/Detector
                    </a>
                    ); upload diagnostic log file from the firewall appliance you want to diagnose.
                  </td>
                  <td>
                    Download WSM 12.7.2 Update 2 or higher, launch the Cyclops Blink Detector and
                    select the firewall appliance you want to diagnose.
                  </td>
                  <td>
                    Log in to WatchGuard Cloud. From the Cyclops Blink Detector widget, select
                    the appliances you want to diagnose.
                  </td>
                </tr>
                <tr>
                  <th>
                    Data Required
                  </th>
                  <td>
                    Must share diagnostic log file (support.tgz) with WatchGuard.
                  </td>
                  <td>
                    Diagnosis provided locally with no data/file provided to WatchGuard.
                  </td>
                  <td>
                    WatchGuard Cloud directly queries the appliance (as with other WatchGuard
                    Cloud services).
                  </td>
                </tr>
                <tr>
                  <th>
                    Data Retention
                  </th>
                  <td>
                    Optional - User can opt in to allow WatchGuard to keep the diagnostic log
                    file (support.tgz) for research of the botnet. Otherwise, the file is deleted
                    after scan results are displayed to the user.
                  </td>
                  <td>
                    No data collected or retained by WatchGuard.
                  </td>
                  <td>
                    All data related to diagnosis is preserved for 1 year by default. For more
                    information,
                    { ' ' }
                    <a
                      href="https://techsearch.watchguard.com/KB?type=Article&SFDCID=kA16S000000SOBISA4&lang=en_US"
                      target="_blank"
                      rel="noreferrer"
                    >
                      see this article
                    </a>
                    .
                  </td>
                </tr>
                <tr>
                  <th>
                    Get Started
                  </th>
                  <td>
                    Generate diagnostic log file (support.tgz) and upload for immediate results.
                    For more information, see the instructions in the
                    { ' ' }
                    <a href="http://detection.watchguard.com/Detector">
                      Cyclops Blink Web Detector
                    </a>
                    { ' ' }
                    user interface.
                  </td>
                  <td>
                    Install WSM v12.7.2 Update 2 or higher and select
                    { ' ' }
                    <b>Tools &gt; Cyclops Blink Detector</b>
                    . For more information, see the instructions in
                    { ' ' }
                    <a
                      href="https://www.watchguard.com/help/docs/help-center/en-US/index.html#cshid=1065"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Fireware Help
                    </a>
                    .
                  </td>
                  <td>
                    Log in to WatchGuard Cloud. In the Cyclops Blink Detector dashboard tile, select
                    { ' ' }
                    <b>Scan Fireboxes in your account</b>
                    . For more information, see the instructions in
                    { ' ' }
                    <a
                      href="https://www.watchguard.com/help/docs/help-center/en-US/index.html#cshid=16200"
                      target="_blank"
                      rel="noreferrer"
                    >
                      WatchGuard Cloud Help
                    </a>
                    .
                  </td>
                </tr>
              </tbody>
            </table>
            <h5>STEP 2: Remediate</h5>
            <p>
              If you have a firewall appliance that is infected with the Cyclops Blink botnet,
              follow the detailed instructions
              {' '}
              <a
                href="https://techsearch.watchguard.com/KB?type=Article&SFDCID=kA16S000000SNyiSAG&lang=en_US"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              { ' ' }
              to restore your appliance to a clean state and update it to the latest version of
              Fireware OS. You will also need to update the passphrases for the Status and Admin
              device management accounts and replace any other secrets, credentials, and
              passphrases configured on the appliance. Finally, make sure the policies that control
              firewall management are configured so that unrestricted access from the Internet is
              not allowed. This is the recommended best practice. If you need to manage your
              WatchGuard appliance remotely, see
              { ' ' }
              <a
                href="https://techsearch.watchguard.com/KB?type=Article&SFDCID=kA10H000000XeAtSAK&lang=en_US"
                target="_blank"
                rel="noreferrer"
              >
                this article
              </a>
              { ' ' }
              to help you do so securely.
            </p>
            <p>
              If you do not plan to remediate immediately, we recommend you take the appliance
              offline until you remove the botnet.
            </p>
            <h5>STEP 3: Prevent</h5>
            <p>
              While remediation already includes these steps, it is critical for all customers,
              whether infected or not to upgrade the appliance to the latest version of
              Fireware OS (available at software.watchguard.com). In addition to containing the
              latest security fixes, the latest Fireware OS also includes automatic systems
              integrity checking, a new security feature that provides enhanced firmware
              protection. As general best practice, WatchGuard continues to recommend that all
              customers regularly change and/or rotate their passphrases and that they make
              sure the policies that control firewall management are configured so that
              unrestricted access from the Internet is not allowed. If you need to manage your
              WatchGuard appliance remotely, see
              { ' ' }
              <a
                href="https://techsearch.watchguard.com/KB?type=Article&SFDCID=kA10H000000XeAtSAK&lang=en_US"
                target="_blank"
                rel="noreferrer"
              >
                this article
              </a>
              { ' ' }
              to help you do so securely.
            </p>
            <h5>
              STEP 4: Investigate
              { ' ' }
              <span className="sub-header">
                (note: this step is only applicable if infection is detected)
              </span>
            </h5>
            <p>
              If you have an appliance that is determined to be infected with the botnet, the
              remediation steps will restore the appliance to a secure state. While there is
              no evidence of exfiltration at this time, if your appliance was infected, it is
              industry best practice to conduct a forensic investigation of your network.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
