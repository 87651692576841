import './UploadFile.css';

import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Form, Col, Row,
} from 'react-bootstrap';
import { Buffer } from 'buffer';
// import axios from 'axios';

import axios from '../../../utils/axios';
import config from '../../../utils/config.json';

function RequestUpload(share) {
  return new Promise((resolve, reject) => axios.get(`${config.apiUrl}/upload-file?share=${share}`)
    .then((res) => {
      if (res.status === 200) {
        if (res.data.success) {
          return resolve(res.data);
        }
        return reject(new Error(res.data.message));
      }
      return reject(new Error('Error requesting upload URL'));
    })
    .catch(() => reject(new Error('Error requesting upload URL'))));
}

function uploadFile(fileData, uploadUrl) {
  return new Promise((resolve, reject) => {
    const putConfig = {
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      headers: {
        'content-type': 'application/gzip',
      },
    };
    const dataBuf = Buffer.from(fileData, 'base64');
    axios.put(uploadUrl, dataBuf, putConfig)
      .then((res) => {
        if (res && res.status === 200) {
          return resolve();
        }
        return reject(new Error('Error uploading file'));
      })
      .catch((error) => reject(error));
  });
}

function confirmUpload(requestId) {
  return new Promise((resolve, reject) => {
    const conirmPostConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    };
    const confirmPostData = {
      requestId,
    };
    axios.post(`${config.apiUrl}/confirm-file`, confirmPostData, conirmPostConfig)
      .then((res) => {
        if (res && res.status === 200) {
          if (res.data.success) {
            return resolve();
          }
          return reject(new Error(res.data.message));
        }
        return reject(new Error('Error confirming file upload'));
      })
      .catch((error) => reject(error));
  });
}

function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class UploadFile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      share: false,
      isProcessing: false,
      errorMessage: null,
    };

    this.onSelectFile = this.onSelectFile.bind(this);
    this.toggleShare = this.toggleShare.bind(this);
    this.submitFile = this.submitFile.bind(this);
  }

  handleUpload(share, file) {
    const {
      fileUploaded,
      uploadRequested,
    } = this.props;
    RequestUpload(share)
      .then((result) => {
        uploadRequested(result.requestId, file.name);
        this.setState({
          isProcessing: false,
        });
        toBase64(file)
          .then((base64File) => {
            const fileData = base64File.split(',')[1];
            uploadFile(fileData, result.uploadUrl)
              .then(() => {
                confirmUpload(result.requestId)
                  .then(() => {
                    fileUploaded(result.requestId);
                    this.setState({
                      isProcessing: false,
                    });
                  })
                  .catch((error) => {
                    this.setState({
                      isProcessing: false,
                      errorMessage: error.message,
                    });
                  });
              })
              .catch((error) => {
                this.setState({
                  isProcessing: false,
                  errorMessage: error.message,
                });
              });
          });
      })
      .catch((error) => {
        this.setState({
          isProcessing: false,
          errorMessage: error.message,
        });
      });
  }

  onSelectFile(event) {
    const acceptedTypes = [
      'application/gzip',
      'application/x-gzip',
      'application/tar',
      'application/x-tar',
      'application/x-tgz',
      'application/x-gtar',
      'application/x-compress',
      'application/x-compressed',
    ];
    event.stopPropagation();
    event.preventDefault();
    const { files } = event.target;
    const filtered = Array.from(files).filter((o) => acceptedTypes.includes(o.type));
    if (filtered.length < files.length) {
      this.setState({
        errorMessage: 'Files must be a gzip or tar archive',
      });
    }

    const sizeFiltered = filtered.filter((o) => o.size < 64000000);
    if (sizeFiltered.length < filtered.length) {
      this.setState({
        errorMessage: 'Files must be less than 64MB',
      });
    }

    if (sizeFiltered.length !== 0) {
      this.setState({
        files: sizeFiltered,
      });
    }
  }

  toggleShare(e) {
    this.setState({
      share: e.target.checked,
    });
  }

  submitFile(event) {
    const {
      files,
      share,
    } = this.state;

    if (event) {
      event.preventDefault();
    }

    this.setState({
      isProcessing: true,
    });

    files.forEach((f) => {
      this.handleUpload(share, f);
    });
  }

  render() {
    const {
      errorMessage,
      isProcessing,
      share,
      files,
    } = this.state;

    function submitDisabled() {
      return !files;
    }

    function getFileList() {
      if (!files) {
        return '';
      }
      return files.map((e) => e.name).join(', ');
    }

    return (
      <div id="UploadFile">
        <div id="UploadFileContainer">
          <Form>
            <Row>
              <Col xs={12} className="form-col select-image">
                <div className="upload-file-controls">
                  <button
                    className="submit-button"
                    tabIndex="0"
                    type="button"
                    onClick={() => { this.upload.click(); }}
                    onKeyPress={() => { this.upload.click(); }}
                    disabled={isProcessing}
                  >
                    Select Diagnostic Log File (support.tgz)
                  </button>
                  <input
                    id="FileUpload"
                    type="file"
                    ref={(el) => { this.upload = el; }}
                    style={{ display: 'none' }}
                    onChange={this.onSelectFile}
                    multiple
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="form-col file-list">
                {files ? (
                  <span className="label">Selected Files: </span>
                ) : ''}
                {getFileList()}
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="form-col acknowledge-section">
                <Form.Check
                  type="checkbox"
                  className="form-checkbox"
                  checked={share}
                  onChange={this.toggleShare}
                />
                <Form.Label className="acknowledgement">
                  Optional: By selecting the check box, I agree to allow WatchGuard to retain
                  this/these diagnostic file(s):
                  <ul>
                    <li>
                      (i) to aid in the overall investigation into the malware; and
                    </li>
                    <li>
                      (ii) to support and improve WatchGuard&apos;s products and services.
                    </li>
                  </ul>
                  Whether I select the check box or not does not affect my ability to diagnose
                  my appliances.
                </Form.Label>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="form-col">
                <div className="upload-file-controls">
                  <button
                    className={!files ? 'submit-button disabled' : 'submit-button'}
                    tabIndex="0"
                    type="submit"
                    onClick={submitDisabled() ? () => {} : this.submitFile}
                    onKeyPress={submitDisabled() ? () => {} : this.submitFile}
                    disabled={submitDisabled()}
                  >
                    Submit
                  </button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="form-col file-list">
                <i className="subtext">
                  By clicking
                  { ' ' }
                  <b>Submit</b>
                  { ' ' }
                  I agree to the Cyclops Blink Web Detector
                  { ' ' }
                  <a href="/Terms">
                    Terms of Use
                  </a>
                </i>
              </Col>
            </Row>
          </Form>
          {isProcessing
            ? (
              <div className="loading-spinner">
                <span><i className="fas fa-spinner fa-spin" /></span>
                <span className="loading-message">Preparing File(s)</span>
              </div>
            )
            : ''}
          <div className="upload-file-error">
            {errorMessage}
          </div>
        </div>
      </div>
    );
  }
}

UploadFile.propTypes = {
  uploadRequested: PropTypes.func.isRequired,
  fileUploaded: PropTypes.func.isRequired,
};

export default UploadFile;
