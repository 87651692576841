import './BannerImage.css';

import React from 'react';

function BannerImage() {
  return (
    <div id="BannerImage">
      <img className="banner" src="/images/banner-image.png" alt="banner" />
    </div>
  );
}

export default BannerImage;
