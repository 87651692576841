import './HeaderNavFull.css';

import * as React from 'react';
import { Link } from 'react-router-dom';

function HeaderNavFull() {
  return (
    <div id="HeaderNavFull">
      <div id="HeaderNavContainer">
        <div className="nav-menu-bar">
          <div className="nav-item">
            <Link className="nav-link" to="/">Overview</Link>
          </div>
          <div className="nav-item">
            <Link className="nav-link" to="/Detector">Detector</Link>
          </div>
          <div className="nav-item">
            <Link className="nav-link" to="/Resources">FAQ &amp; Resources</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
export default HeaderNavFull;
