import './App.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';

import Header from '../Header';
import Footer from '../Footer';
import Home from '../Home';
import Detector from '../Detector';
import Resources from '../Resources';
import Terms from '../Terms';

function App(props) {
  const { history } = props;
  return (
    <Container id="App" fluid>
      <div id="AppContainer">
        <Header history={history} />
        <Routes>
          <Route path="/Detector" element={<Detector />} />
          <Route path="/Resources" element={<Resources />} />
          <Route path="/Terms" element={<Terms />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
      <Footer />
    </Container>
  );
}

App.propTypes = {
  history: PropTypes.object.isRequired,
};

export default App;
