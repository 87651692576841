import './DropdownClosed.css';

import React from 'react';
import PropTypes from 'prop-types';

function DropdownClosed(props) {
  const { openDropdown } = props;
  return (
    <div
      id="DropdownOpened"
      tabIndex="0"
      role="button"
      onClick={openDropdown}
      onKeyPress={openDropdown}
    >
      <i className="fas fa-bars dropdown-icon" />
    </div>
  );
}

DropdownClosed.propTypes = {
  openDropdown: PropTypes.func.isRequired,
};

export default DropdownClosed;
