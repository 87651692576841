import axios from 'axios';
import axiosRetry from 'axios-retry';

const axiosInstance = axios.create();

axiosRetry(axiosInstance, {
  retries: 5,
  retryCondition: (error) => (
    axiosRetry.isNetworkOrIdempotentRequestError(error) || error.response.status === 429
  ),
  retryDelay: axiosRetry.exponentialDelay,
});

export default axiosInstance;
