import './Terms.css';

import React from 'react';

import BannerImage from '../BannerImage';

function Terms() {
  return (
    <div id="Terms">
      <div id="TermsContainer">
        <BannerImage />
        <div id="TermsContent">
          <h1>Cyclops Blink Web Detector Terms Of Use</h1>
          <div className="text-container">
            <p>
              IMPORTANT - READ CAREFULLY BEFORE ACCESSING OR USING THE CYCLOPS BLINK WEB DETECTOR:
            </p>
            <p>
              These Cyclops Blink Web Detector (the &quot;Service&quot;) Terms of Use are a legal
              agreement between you (either an individual or a single entity) and WatchGuard
              Technologies, Inc. (&quot;WatchGuard&quot;) for the Service
              (the&quot;Agreement&quot;).
            </p>
            <p>
              WatchGuard is willing to provide you with access to and use of the Service on the
              condition that you accept the terms of this Agreement. Please read this Agreement
              carefully. By accessing or using the Service you agree to be bound by the terms
              of this Agreement.
            </p>
            <ol>
              <li>
                <u>Ownership</u>
                { ' ' }
                . The Service is protected by copyright laws as well as other intellectual
                property laws and treaties. This is not an agreement for sale. All title and
                copyrights in and to the Service are owned by WatchGuard or its licensors. Your
                rights to access and use the Service are as specified in this Agreement, and
                WatchGuard retains all rights not expressly granted to you in this Agreement.
                Nothing in this Agreement waives WatchGuard&apos;s rights under U.S. copyright
                law or any other law or treaty.
              </li>
              <li>
                <u>Permitted Uses</u>
                { ' ' }
                . WatchGuard grants you the right to access and use the Service subject to
                your compliance with this Agreement.
              </li>
              <li>
                <u>Prohibited Uses</u>
                { ' ' }
                . You are responsible for all your activity related to the Service and may not
                use the Service for any purpose other than as set forth in this Agreement. Any
                conduct by you that, in WatchGuard&apos;s sole determination, restricts or
                inhibits any other user from using or enjoying the Service will not be permitted.
                Without limitation of the foregoing, you may not (and may not permit any third
                party to): (a) use the Service in connection with any fraudulent or illegal
                conduct, transaction, or business; (b) use the Service in a manner that is
                unlawful or violates any third party proprietary rights; (c) use the Service to
                transmit software viruses or any other computer codes, files, or programs that
                are designed or intended to disrupt, damage, limit, obtain unauthorized access
                to, or interfere with the proper function of the Service or any other system;
                (d) take any action that imposes or may impose (as determined by WatchGuard in
                its sole discretion) an unreasonable or disproportionately large load on
                WatchGuard&apos;s (or its third party providers&apos;) infrastructure; (e)
                interfere or attempt to interfere with the proper working of the Service; (f)
                bypass, circumvent, or attempt to bypass or circumvent any measures WatchGuard
                may use to prevent or restrict access to the Service; (g) frame or mirror any
                portion of the Service or incorporate any portion of the Service into any product
                or service; (h) decipher, decompile, disassemble, reverse engineer or otherwise
                attempt to derive any source code or underlying ideas or algorithms of the
                Service or make or attempt to make any modification to the Service, except to
                the limited extent applicable laws specifically prohibit this restriction; (i)
                modify, translate, or otherwise create derivative works of the Service; (j)
                sell, resell, copy, rent, lease, loan, distribute, or charge any party for
                access to the Service; (k) use or launch any automated system, including
                &qout;robots,&quot; &quot;spiders,&quot; or &quot;offline readers,&quot; to
                access the Service; or (l) act as an intermediary, aggregator, or service
                bureau yourself or on behalf of any third party.
              </li>
              <li>
                <u>No Warranty</u>
                { ' ' }
                . WATCHGUARD MAKES NO WARRANTY WITH RESPECT TO THE SERVICE. ALL WARRANTIES,
                EXPRESS OR IMPLIED, ARE EXCLUDED. WITHOUT LIMITING THE GENERALITY OF THE
                FOREGOING, WATCHGUARD MAKES NO WARRANTY OF MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, OR OF UNINTERRUPTED OR ERROR-FREE
                OPERATION.
              </li>
              <li>
                <u>Limitation of Liability</u>
                { ' ' }
                . IN NO EVENT WILL WATCHGUARD BE LIABLE FOR ANY INCIDENTAL, SPECIAL, OR
                CONSEQUENTIAL DAMAGES, OR FOR ANY AMOUNTS EXCEEDING $100 IN THE AGGREGATE.
                WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WATCHGUARD IS NOT LIABLE
                FOR FRUSTRATION OF BUSINESS EXPECTATIONS, LOSS OF PROFITS, LOSS OF DATA, COST
                OF SUBSTITUTE SERVICES, DOWNTIME COST, OR ANY CLAIM AGAINST YOU BY ANY OTHER
                PARTY.
              </li>
              <li>
                <u>Modifications to the Service and Discontinuation</u>
                { ' ' }
                . WatchGuard reserves the right to modify or discontinue, temporarily or
                permanently, the Service (or any part thereof) with or without notice.
                WatchGuard will not be liable to you or to any third party for any modification,
                suspension, or discontinuation of the Service.
              </li>
              <li>
                <u>United States Government Restricted Rights</u>
                { ' ' }
                . The Service is provided with Restricted Rights. Use, duplication or
                disclosure by the U.S. Government or any agency or instrumentality thereof
                is subject to restrictions as set forth in subdivision (c)(1)(ii) of the Rights
                in Technical Data and Computer Software clause at DFARS 252.227-7013, or in
                subdivision (c)(1) and (2) of the Commercial Computer Software -- Restricted
                Rights Clause at 48 C.F.R. 52.227-19, as applicable. Manufacturer is WatchGuard
                Technologies, Inc., 505 5th Ave. South, Suite 500, Seattle, WA 98104.
              </li>
              <li>
                <u>Termination</u>
                { ' ' }
                . Your right to use the Service will automatically terminate if you fail
                to comply with any provisions of this Agreement.
              </li>
              <li>
                <u>Miscellaneous Provisions</u>
                { ' ' }
                . Washington law applies to all matters arising under or relating to this
                Agreement, without regard to any choice-of-law rules that might direct the
                application of another jurisdiction&apos;s laws. The United Nations Convention
                on Contracts for the International Sale of Goods is excluded. This is the
                entire Agreement between you and WatchGuard relating to the Service, and
                supersedes any other documentation, communications, advertising, or
                representations concerning the Service. IF THE SERVICE IS BEING USED BY AN
                ENTITY, THE INDIVIDUAL INDICATING AGREEMENT TO THESE TERMS REPRESENTS AND
                WARRANTS THAT (A) SUCH INDIVIDUAL IS DULY AUTHORIZED TO ACCEPT THIS AGREEMENT
                ON BEHALF OF THE ENTITY AND TO BIND THE ENTITY TO THE TERMS OF THIS AGREEMENT;
                (B) THE ENTITY HAS THE FULL POWER, CORPORATE OR OTHERWISE, TO ENTER INTO THIS
                AGREEMENT AND PERFORM ITS OBLIGATIONS UNDER THIS AGREEMENT AND; (C) THIS
                AGREEMENT AND THE PERFORMANCE OF THE ENTITY&apos;S OBLIGATIONS UNDER THIS AGREEMENT
                DO NOT VIOLATE ANY THIRD-PARTY AGREEMENT TO WHICH THE ENTITY IS A PARTY. No
                change or modification of this Agreement will be valid unless it is in writing
                and is signed by WatchGuard.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Terms;
